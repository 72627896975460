import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/index')
  },
  {
    path: '/channelmanager',
    name: 'channelmanager',
    component: () => import('../views/channelmanager')
  },
  {
    path: '/bookingengine',
    name: 'bookingengine',
    component: () => import('../views/bookingengine')
  },
  {
    path: '/agentportal',
    name: 'agentportal',
    component: () => import('../views/agentportal')
  },
  {
    path: '/pms',
    name: 'pms',
    component: () => import('../views/pms')
  },
  {
    path: '/marketing',
    name: 'Marketing',
    component: () => import('../views/marketing')
  },
  {
    path: '/price',
    name: 'Price',
    component: () => import('../views/price')
  },
  {
    path: '/page-aboutus',
    name: 'aboutus',
    component: () => import('../views/page-aboutus')
  },
  {
    path: '/page-contact-detail',
    name: 'contactus',
    component: () => import('../views/page-contact-detail')
  },
  {
    path: '/thankyou',
    name: 'thankyou',
    component: () => import('../views/page-thankyou')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/page-error')
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
